import React, {ReactElement, useEffect, useState} from 'react';
import {Loading, NewCohortModal, PolygonViewer, VirtualizedTable} from '../index';
import './CohortList.scss';
import {DataSourceBuilder} from "../Common/VirtualizedTable/DataSourceBuilder";
import {Cohort, CurrentUser, DemandInfluenceFormOptions} from "../../types";
import {Icon, Modal, Tooltip} from '@vacasa/react-components-lib';
import {CohortAssignmentModal} from "./CohortAssignmentModal";
import {UiUtils} from "../../utils";


interface CohortListProps {
    cohortData: Cohort[];
    isFetchingCohorts: boolean;
    onRefresh: () => void;
    onUpdateCohort: (cohort: Cohort) => void;
    currentUser: CurrentUser;
    refetchCohorts: () => void;
}

type CohortListComponent = (props: CohortListProps) => ReactElement<any, any> | null;

export const CohortList: CohortListComponent = (props) => {
    const {cohortData, isFetchingCohorts, onUpdateCohort, currentUser, refetchCohorts} = props;
    const [additionalCohortData, setAdditionalCohortData] = useState<Cohort[]>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openPolygonModal, setOpenPolygonModal] = useState<boolean>(false);
    const [openNewCohortModal, setOpenNewCohortModal] = useState<boolean>(false);
    const [selectedCohort, setSelectedCohort] = useState<Cohort>();
    const isApprover = currentUser?.approver;
    
    const selectCohort = (cohort: Cohort) => {
        setSelectedCohort(cohort);
        setOpenModal(true);
        setOpenPolygonModal(false);
    }

    const openPolygon = (cohort: Cohort) => {
        setSelectedCohort(cohort);
        setOpenModal(false);
        setOpenPolygonModal(true);
    }

    const builder = new DataSourceBuilder<Cohort>();

    const newCohort = () => {
        setOpenNewCohortModal(true);
    }

    useEffect(() => {
        if (!isFetchingCohorts && !!cohortData) {
            let newCohortData = [];
            cohortData.forEach((c, idx) => {
                let cohortCopy = {...c};
                cohortCopy.model_name = cohortCopy.model_id === 4000  ? "Northstar" : "Alan";
                newCohortData.push(cohortCopy);
            })
            setAdditionalCohortData(newCohortData);
        }
    }, [cohortData, isFetchingCohorts]);

    builder.addColumn({
        label: 'ID',
        field: 'id',
        displayConfiguration: {flexGrow: 0.5, justifyContent: 'right'},
    });
    builder.addColumn({
        label: 'Name',
        field: 'name',
        displayConfiguration: {flexGrow: 1.2, justifyContent: 'center'},
    });
    builder.addColumn({
        label: 'Units',
        field: 'unit_count',
        displayConfiguration: {flexGrow: 0.3, justifyContent: 'right'}
    });

    builder.addColumn({
        label: 'Active',
        field: 'active',
        displayConfiguration: {flexGrow: 0.4, justifyContent: 'right'},
        fieldConfiguration: {
            customLeftComponent: (row) => {
                if (!row.active) return <Icon.MinusCircle height={24} width={24}/>;
                return <Icon.CheckSquare height={24} width={24}/>;
            }, format: () => <></>
        }
    });
    builder.addColumn({label: 'State', field: 'state', displayConfiguration: {flexGrow: 0.5, justifyContent: 'right'}});
    builder.addColumn({label: 'Area', field: 'polygon_area', displayConfiguration: {flexGrow: 0.5, justifyContent: 'right'}});
    // builder.addColumn({label: 'Region', field: 'region', displayConfiguration: {justifyContent: 'right'},});
    builder.addColumn({label: 'Model', field: 'model_name', displayConfiguration: {flexGrow: 0.7, justifyContent: 'right'}});
    builder.addColumn({label: 'Manager', field: 'manager', displayConfiguration: {justifyContent: 'right'}});
    builder.addColumn({label: 'Analyst', field: 'analyst', displayConfiguration: {justifyContent: 'right'}});
    builder.addColumn({
        label: 'Actions',
        field: 'action',
        displayConfiguration: {flexGrow: 0.5},
        func: (row) => (
            <React.Fragment>
                <div className="action-button-list">
                    {isApprover ?
                        <Tooltip message={
                            "Change analyst assignment"
                        }>
                            <div className="action-button action-button-icon"
                                 onClick={() => {
                                     selectCohort(row)
                                 }}>
                                {
                                    row.loading
                                        ?
                                        <Icon.Loader className={"spinning-icon"} height={24} width={24}/>
                                        :
                                        <Icon.Edit height={24} width={24}/>
                                }
                            </div>
                        </Tooltip>
                        :
                        <></>
                    }
                    {isApprover ?
                        <Tooltip message={
                            "View and update Polygon"
                        }>
                            <div className="action-button action-button-icon"
                                 onClick={() => {
                                     openPolygon(row)
                                 }}>
                                {
                                    row.loading
                                        ?
                                        <Icon.Loader className={"spinning-icon"} height={24} width={24}/>
                                        :
                                        <Icon.Map height={24} width={24}/>
                                }
                            </div>
                        </Tooltip>
                        :
                        <></>
                    }
                </div>
            </React.Fragment>
        )
    });

    builder.setSortable({field: 'name', order: 'asc'});
    builder.setFilterHeader({
        options: [
            { field: "active", values: ['ALL', true, false], type: "select"},
            { field: "unit_count", type: "range", options: "int"},
            { field: "state", values:  UiUtils.getStateOptions(cohortData), type: "select"},
            { field: "polygon_area", type: "range", options: "int"},
            { field: "model_name", values:  ["ALL", "Northstar", "Alan"], type: "select"},
            { field: "region", values:  UiUtils.getRegionOptions(cohortData), type: "select"},
            { field: "manager", values:  UiUtils.getManagerOptions(cohortData), type: "select"},
            { field: "analyst", values:  UiUtils.getAnalystOptions(cohortData), type: "select"},
        ],
        initialFilters: [
            { field: "active", value: "true", type: "boolean"}
        ]
    });

    builder.addPagination({remote: false});


    return (
        <div>
            <div className="row" style={{display: "flex", justifyContent: "flex-end"}}>
                <div className="refetch-icon">
                    <Tooltip message="Refresh Holidayss">
                        <Icon.RefreshCCW className="pointer" height={24} width={24} onClick={refetchCohorts}/>
                    </Tooltip>
                    <Tooltip message="(WiP -- Disabled) Add Cohort">
                         <span className="demand-influence-icon" aria-disabled={true}>
                             {/*onClick={newCohort}>*/}
                            <Icon.PlusCircle className="pointer" height={24} width={24}/>
                        </span>
                    </Tooltip>
                </div>
            </div>
            {isFetchingCohorts || !additionalCohortData
                ? <Loading className="cohort-list-loading"/>
                :
                <VirtualizedTable
                    className="cohort-list-table"
                    dataSource={builder.build(additionalCohortData)}
                    onRowChange={() => null}
                    headerOptions={{height: 60}}
                />
            }
            <Modal
                canExit={true}
                showModal={openNewCohortModal}
                setShowModal={setOpenNewCohortModal}
                size='medium'
            >
                <NewCohortModal
                    closeModal={(refresh?) => {
                        setOpenNewCohortModal(false);
                        if(refresh) {
                            refetchCohorts();
                        }
                    }}
                    cohortNames={cohortData.filter(c => c.active).map(c => c.name.toUpperCase())}
                    viewOnly={!isApprover}
                />
            </Modal>
            <Modal
                canExit={true}
                showModal={openModal}
                setShowModal={setOpenModal}
                size='medium'
            >
                <CohortAssignmentModal
                    closeModal={() => setOpenModal(false)}
                    selectedCohort={selectedCohort}
                    onUpdateCohort={onUpdateCohort}
                    viewOnly={!isApprover}
                />
            </Modal>
            <Modal
                canExit={true}
                showModal={openPolygonModal}
                setShowModal={setOpenPolygonModal}
                size='large'
            >
                <PolygonViewer
                    selectedCohort={selectedCohort}
                    closeModal={() => setOpenPolygonModal(false)}
                    onUpdateCohort={onUpdateCohort}
                    viewOnly={!isApprover}
                />
            </Modal>
        </div>
    );
};
