import React, {useEffect, useState} from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import './ReblAccordion.scss';
import {TableRowProps} from "react-virtualized";
import {ReblRuleset} from "../../types";
import {Icon, withTooltip, Tooltip } from '@vacasa/react-components-lib';


interface ReblAccordionProps {
    tableProps: TableRowProps;
    expanded: string | boolean;
    handleChange: (id: string, index: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
}


export const ReblAccordion: React.FC<ReblAccordionProps> = (props) => {

    const {tableProps, expanded, handleChange} = props;
    const [extraData, setExtraData] = useState<ReblRuleset["extra_data"]>();

    const styles = {
        ...tableProps.style
    };

    const getTD = (s: string) => {
        return (
        <div>{s.length > 35 ?
            withTooltip(
                s,
                <div>
                    <Icon.AlertCircle height={14} width={14} />
                    {s.substring(0, 38)}
                    {s.length > 38 ? "..." : ""}
                </div>,
                { placement: 'bottom' }
            )
            :
            <>{s}</>
        }</div>
        )
    }

    useEffect(() => {
        const extraDetailsFromData: ReblRuleset["extra_data"] = tableProps.rowData.extra_data;
        setExtraData(extraDetailsFromData);
    }, [tableProps.rowData.extra_data]);

    return (
        <div key={tableProps.rowData.id} className="accordion-row" style={styles}>
            <MuiAccordion key={tableProps.rowData.id} expanded={expanded === tableProps.rowData.id}
                          onChange={handleChange(tableProps.rowData.id, tableProps.index)}>
                <MuiAccordionSummary
                    className={"accordion-summary"}>
                {tableProps.columns}
                </MuiAccordionSummary>
                <MuiAccordionDetails className={"accordion-details"}>
                    <table className={"table"}>
                        <thead>
                        <tr className={"table-header"}>
                            <th className={"adj-type-col"}>Adjustment Type</th>
                            <th className={"action-col"}>Action</th>
                            <th className={"flag-col"}>Flag</th>
                            <th className={"season-col"}>Season</th>
                            <th className={"created-col"}>Created</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!!extraData ?
                            <tr className={"table-body"}>
                                <td>{getTD(extraData.adjustment_type)}</td>
                                <td>{getTD(extraData.action)}</td>
                                <td>{getTD(extraData.flag)}</td>
                                <td>{getTD(extraData.season)}</td>
                                <td>{getTD(extraData.created)}</td>
                            </tr>
                            :
                            <tr className={"table-body"}>
                                <td colSpan={7} className={"empty-record"}> No records</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </MuiAccordionDetails>
            </MuiAccordion>
        </div>
    );
}
